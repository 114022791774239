import Banner from "./components/Banner"
import Carousel from "./components/Carousel"
import ChatStyle from "../chat/ChatStyle"
import Column from "./Column"
import Comments from "./media/Comments"
import Details from "./media/Details"
import IconStyle from "../general/IconStyle"
import PageMenu from "../menu/PageMenu"
import Row from "./Row"
import Tab from "./tabs/Tab"
import Video from "./media/Video"
import { formatIconStyle } from "~/helpers/icon/icon"

class Component {
    constructor() {
        this.id = null
        this.style = null
        this.type = null

        this.props = null
    }

    fromData(data) {

        this.id = data.id
        this.style = data.style
        this.type = data.type

        switch (data.type) {
          case 'row':
            // console.error('COMPONENT CALL NEW ROW', data)

            this.children = data?.children.map((r) => {
              const row = new Component()
              row.fromData(r)

              return row
            })
            this.style = data.style
            this.props = data.props
            break

          case 'col':
            this.children = data?.children.map((r) => {
              const row = new Component()
              row.fromData(r)

              return row
            })
            this.style = data.style
            this.props = data.props
            break

          case 'banner':
            // console.error('COMPONENT CALL NEW BANNER', data)

            const banner = new Banner()
            banner.fromData(data.props)
            this.props = banner
            this.children = data.children || null

            break

          case 'BannerCarousel':
            // console.error('COMPONENT CALL NEW BannerCarousel', data)

            const carousel = new Carousel()
            carousel.fromData(data.props)
            this.props = carousel
            break

          case 'tabs':
            // console.error('COMPONENT CALL NEW TABS', data)

            this.tabs = data.tabs.map((t) => {
              const tab = new Tab()
              tab.fromData(t)

              return tab
            })
            this.style = data.style
            this.props = data.props
            this.icon = data.icon
            this.changeOnHover = data.changeOnHover
            break

          case 'ContentGrid':
            this.style = data.style
            this.props = data.props
            this.components = data.components
            if (data?.icon) {
              this.style.generalStyle = {
                ...this.style?.generalStyle,
                iconSettings: formatIconStyle({
                  style: {
                    ...data.icon.style,
                    size: data.icon.props.size,
                  },
                  props: data.icon.props,
                }),
              }
            }
            break

          case 'ContentSingle':
            // console.error('COMPONENT CALL NEW SINGLE', data)

            this.style = data.style
            this.props = data.props
            this.components = data.components
            break
          case 'image':
            // console.error('COMPONENT CALL NEW IMAGE', data)

            this.style = data.style
            this.props = data.props
            break
          case 'label':
            // console.error('COMPONENT CALL NEW LABEL', data)

            this.style = data.style
            this.props = data.props
            break

          case 'video':
            // console.error('COMPONENT CALL NEW VIDEO', data)

            const video = new Video()
            video.fromData({})
            this.video = video

            this.style = data.style
            this.props = data.props
            break

          case 'RichTextContent':
            // console.error('COMPONENT CALL NEW RichTextContent', data)

            this.style = data.style
            this.props = data.props
            break

          case 'NewestVideos':
            // console.error('COMPONENT CALL NEW NewestVideos', data)

            this.style = data.style
            this.props = data.props
            break

          case 'WatchedVideos':
            this.style = data.style
            this.props = data.props
            break

          case 'TonosComponent':
            this.style = data.style
            this.props = data.props
            break

          case 'details':
            // console.error('COMPONENT CALL NEW DETAILS', data)

            const details = new Details()
            details.fromData({})
            this.details = details

            this.style = data.style
            this.props = data.props
            break

          case 'details':
            // console.error('COMPONENT CALL NEW COMMENTS', data)

            const comments = new Comments()
            comments.fromData({})
            this.comments = comments

            this.style = data.style
            this.props = data.props
            break

          case 'contentDetails':
            // console.error('COMPONENT CALL NEW COMMENTS', data)

            this.style = data.style
            this.props = data.props
            this.components = data.components
            this.engagements = data.engagements
            break

          case 'search':
            // console.error('COMPONENT CALL NEW COMMENTS', data)

            // const comments = new Comments()
            // comments.fromData({})
            // this.comments = comments

            this.style = data.style
            this.props = data.props
            break

          case 'menu':
            // console.error('COMPONENT CALL NEW MENU', data)

            // this.menuData = null
            const pageMenu = new PageMenu()
            pageMenu.fromData(data)
            this.menuData = pageMenu

            // this.style = data.style
            this.props = data.props
            // delete this.style
            break

          case 'ContentCarousel':
            // console.error('COMPONENT CALL NEW ContentCarousel', data.icon)
            this.style = data.style
            this.props = data.props
            this.components = data.components

            const generalStyle = new IconStyle()
            generalStyle.fromData(data.icon)
            this.generalStyle = generalStyle

            break

          case 'showCase':
            // console.error('COMPONENT CALL NEW showCase', data)

            this.style = data.style
            this.props = data.props
            // delete this.style

            this.grid = data.components.grid

            this.banner = data.components.banner

            const bannerProps = new Banner()
            bannerProps.fromData(data.components.banner.props)
            this.banner.props = bannerProps

            this.banner.children = data.components.banner?.children || []

            break
          case 'player':
            // console.error('COMPONENT CALL NEW showCase', data)

            this.style = data.style
            this.props = data.props
            this.components = data.components

            //change type to player rename file "Video.vue to Player.vue"
            this.type = 'video'

            break

          case 'followingMedia':
            // console.error('COMPONENT CALL NEW VIDEO', data)

            this.style = data.style
            this.props = data.props
            this.components = data.components

            break
          case 'relatedGroup':
            // console.error('COMPONENT CALL NEW showCase', data)

            this.style = data.style
            this.props = data.props
            this.components = data.components

            break

          case 'adComponent':
            this.style = data.style
            this.props = data.props
            this.children = data.children

            break

          case 'comments':
            this.style = data.style
            this.props = data.props
            this.components = data.components

            break

          case 'list':
            this.style = data.style
            this.props = data.props
            this.components = data.components
            this.item = data.item

            break

          case 'chat':
            const layout = new ChatStyle()
            layout.fromData(data.layout)
            this.style = layout
            this.props = data.props
            // this.components = data.components

            break

          case 'button':
            this.style = data.style
            this.props = data.props
            break

          case 'customJsComponent':
            this.style = null
            this.props = data.props
            break

          default:
            // console.error('COMPONENT CALL NEW default present handle next', data)
            this.name = 'component not implemented'
            this.props = `props not added component type is: '${data.type}'`
            break
        }

    }
}

export default Component