import { defineNuxtPlugin } from '#app/nuxt'
import { LazyBannerCarousel, LazyTheBody, LazyTheFooter, LazyTheHeader, LazyTheTest, LazyAdComponent, LazyAdComponentTheAdComponent, LazyBanner, LazyButton, LazyCard, LazyChat, LazyCol, LazyColWrapper, LazyComments, LazyContentCarousel, LazyContentGrid, LazyContentSingle, LazyContentSlider, LazyContentDetails, LazyCustomJsComponent, LazyFollowingMedia, LazyFooterImage, LazyFooterLabel, LazyFooterLogo, LazyFooterMenu, LazyFooterSocialMedia, LazyFooterSpacing, LazyHeaderV2CustomComponent, LazyHeaderV2Label, LazyHeaderV2Language, LazyHeaderV2Logo, LazyHeaderV2Menu, LazyHeaderV2Notification, LazyHeaderV2Profile, LazyHeaderV2Search, LazyHeaderV2Sidebar, LazyHeaderV2Spacing, LazyIdentifier, LazyImage, LazyLabel, LazyList, LazyMenu, LazyNewestVideos, LazyPageLoading, LazyPageNoSearchResult, LazyPageNotFound, LazyPageRedirectingToLogin, LazyRelatedGroup, LazyRichTextContent, LazyRow, LazySearch, LazySectionCards, LazySectionHeros, LazySectionSceletonCards, LazySectionSlides, LazyShowCase, LazySidebar, LazyTabs, LazyTonosComponent, LazyVideoDetails, LazyVideoPlayer, LazyVideoTheTest1, LazyVideo, LazyVpIcon, LazyWatchedVideos, LazyWidget, LazyMyLibEs, LazyMyLibUmd, LazyRenderCacheable } from '#components'
const lazyGlobalComponents = [
  ["BannerCarousel", LazyBannerCarousel],
["TheBody", LazyTheBody],
["TheFooter", LazyTheFooter],
["TheHeader", LazyTheHeader],
["TheTest", LazyTheTest],
["AdComponent", LazyAdComponent],
["AdComponentTheAdComponent", LazyAdComponentTheAdComponent],
["Banner", LazyBanner],
["Button", LazyButton],
["Card", LazyCard],
["Chat", LazyChat],
["Col", LazyCol],
["ColWrapper", LazyColWrapper],
["Comments", LazyComments],
["ContentCarousel", LazyContentCarousel],
["ContentGrid", LazyContentGrid],
["ContentSingle", LazyContentSingle],
["ContentSlider", LazyContentSlider],
["ContentDetails", LazyContentDetails],
["CustomJsComponent", LazyCustomJsComponent],
["FollowingMedia", LazyFollowingMedia],
["FooterImage", LazyFooterImage],
["FooterLabel", LazyFooterLabel],
["FooterLogo", LazyFooterLogo],
["FooterMenu", LazyFooterMenu],
["FooterSocialMedia", LazyFooterSocialMedia],
["FooterSpacing", LazyFooterSpacing],
["HeaderV2CustomComponent", LazyHeaderV2CustomComponent],
["HeaderV2Label", LazyHeaderV2Label],
["HeaderV2Language", LazyHeaderV2Language],
["HeaderV2Logo", LazyHeaderV2Logo],
["HeaderV2Menu", LazyHeaderV2Menu],
["HeaderV2Notification", LazyHeaderV2Notification],
["HeaderV2Profile", LazyHeaderV2Profile],
["HeaderV2Search", LazyHeaderV2Search],
["HeaderV2Sidebar", LazyHeaderV2Sidebar],
["HeaderV2Spacing", LazyHeaderV2Spacing],
["Identifier", LazyIdentifier],
["Image", LazyImage],
["Label", LazyLabel],
["List", LazyList],
["Menu", LazyMenu],
["NewestVideos", LazyNewestVideos],
["PageLoading", LazyPageLoading],
["PageNoSearchResult", LazyPageNoSearchResult],
["PageNotFound", LazyPageNotFound],
["PageRedirectingToLogin", LazyPageRedirectingToLogin],
["RelatedGroup", LazyRelatedGroup],
["RichTextContent", LazyRichTextContent],
["Row", LazyRow],
["Search", LazySearch],
["SectionCards", LazySectionCards],
["SectionHeros", LazySectionHeros],
["SectionSceletonCards", LazySectionSceletonCards],
["SectionSlides", LazySectionSlides],
["ShowCase", LazyShowCase],
["Sidebar", LazySidebar],
["Tabs", LazyTabs],
["TonosComponent", LazyTonosComponent],
["VideoDetails", LazyVideoDetails],
["VideoPlayer", LazyVideoPlayer],
["VideoTheTest1", LazyVideoTheTest1],
["Video", LazyVideo],
["VpIcon", LazyVpIcon],
["WatchedVideos", LazyWatchedVideos],
["Widget", LazyWidget],
["MyLibEs", LazyMyLibEs],
["MyLibUmd", LazyMyLibUmd],
["RenderCacheable", LazyRenderCacheable],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
